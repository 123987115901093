import {
  AssignmentOutlined,
  GroupAddOutlined,
  ImportContactsOutlined,
  TouchAppOutlined,
} from '@mui/icons-material';

import { useFlag } from '@headway/feature-flags/react';
import { IconInPerson } from '@headway/helix/icons/InPerson';
import type { IconProps } from '@headway/helix/icons/types';
import { useExtoleUserQuery } from '@headway/ui/hooks/extole';
import { theme } from '@headway/ui/theme';

import { IAuthStore, withStores } from '../../../stores/withStores';
import { DropdownList } from './components/DropdownList';
import { DropdownListLink } from './components/DropdownListLink';
import { DropdownSection } from './components/DropdownSection';

type Props = {
  AuthStore: IAuthStore;
};

const ForProvidersDropdownImpl: React.FC<Props> = ({ AuthStore }) => {
  const query = useExtoleUserQuery();

  const advocateReward = query?.data?.advocateReward ?? '$350';

  const includeResourceCenterLink = useFlag('resourceCenterNavLink', false);

  return (
    <div>
      <DropdownSection data-first-dropdown-section>
        <DropdownList>
          <DropdownListLink
            href="/for-providers"
            icon={ImportContactsOutlined}
            aria-label="Learn more about providers"
          >
            Learn more
          </DropdownListLink>
          <DropdownListLink
            href="/for-providers#get-started"
            icon={TouchAppOutlined}
          >
            Join Headway
          </DropdownListLink>
          <DropdownListLink href="/for-providers/refer" icon={GroupAddOutlined}>
            Refer a provider, get {advocateReward}
          </DropdownListLink>
          <DropdownListLink
            href={process.env.NEXT_PUBLIC_SIGMUND_URL || ''}
            icon={AssignmentOutlined}
          >
            Provider Portal
          </DropdownListLink>
          {includeResourceCenterLink && (
            <DropdownListLink
              href="/resources/all"
              icon={NormalizedInPersonIcon}
            >
              Provider Resource Center
            </DropdownListLink>
          )}
        </DropdownList>
      </DropdownSection>
    </div>
  );
};

function NormalizedInPersonIcon(props: IconProps) {
  return (
    <IconInPerson
      size={24}
      css={{
        '& path': {
          fill: theme.color.primary,
          fillOpacity: 1,
          transform: 'scale(1.5)',
          transformOrigin: 'center center',
        },
      }}
      {...props}
    />
  );
}

const ForProvidersDropdown = withStores(ForProvidersDropdownImpl);

export default ForProvidersDropdown;
