const REDIRECT_TO_QUERY_KEY = 'redirect-to';

const getRedirectPath = (rawRedirectTo: string): string => {
  const destination = new URL(
    rawRedirectTo ?? '/',
    process.env.NEXT_PUBLIC_CARE_URL
  );

  const queryString = destination.searchParams.toString()
    ? `?${destination.searchParams.toString()}`
    : '';

  return `${destination.pathname}${queryString}${destination.hash}`;
};

const decodePath = (rawRedirectTo: string): string => {
  const decoded = decodeURIComponent(rawRedirectTo);
  return getRedirectPath(decoded);
};

const encodePath = (redirectTo: string): string => {
  const path = getRedirectPath(redirectTo);
  return encodeURIComponent(path);
};

export { REDIRECT_TO_QUERY_KEY, decodePath, encodePath };
