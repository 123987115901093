/**
 * See vite.config.ts for the list of defined remix routes.
 */
export const REMIX_ROUTES = [
  '/careers',
  '/m/:slug',
  '/resources/*',
  '/clients/*',
  '/for-providers',
  '/for-providers/accepting-insurance',
  '/for-providers/not-accepting-insurance',
  '/for-providers/groups-accepting-insurance',
  '/for-providers/groups-not-accepting-insurance',
  '/for-providers/referred',
  '/does-my-insurance-cover-therapy',
  '/press',
  '/about-us',
  '/for-health-plans',
  '/blog',
  '/blog/:slug',
  '/legal/*',
];
