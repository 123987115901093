// eslint-disable-next-line lodash/import-scope
import queryString from 'query-string';

import {
  Filters,
  filtersToQueryParams,
} from '@headway/shared/utils/providerSearchFilters';

export const getProvidersSearchPath = ({
  marketSlug,
  filters = {},
}: { marketSlug?: string; filters?: Partial<Filters> } = {}): string => {
  const basePath = marketSlug ? `/search/${marketSlug}` : '/search';

  const [params] = filtersToQueryParams(filters);

  return `${basePath}?${queryString.stringify(params)}`;
};
