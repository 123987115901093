import { Instagram, LinkedIn, Twitter } from '@mui/icons-material';
import { Divider } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import { isFragment } from 'react-is';

import { MarketRead } from '@headway/api/models/MarketRead';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { HeadwayLogo } from '@headway/helix/HeadwayLogo';
import { Link } from '@headway/helix/Link';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { trackEvent } from '@headway/shared/utils/analytics';
import { VisuallyHidden } from '@headway/ui';
import { Chip } from '@headway/ui/Chip';
import { Section, SectionContainer } from '@headway/ui/landing';
import { theme } from '@headway/ui/theme';

import { getProvidersSearchPath } from '../../utils/search';
import {
  LandingPageInteraction,
  SearchEntry,
  trackProviderSearchCompleted,
  trackProviderSearchStarted,
} from '../../utils/searchAnalyticEvents';
import { NavList } from './NavList';
import { NavListLink } from './NavListLink';
import { NavSection } from './NavSection';
import { NavTitle } from './NavTitle';

interface Props {
  userId?: number | undefined;
  markets: MarketRead[];
}

export const Footer: React.FC<Props> = ({ userId, markets: liveMarkets }) => {
  const shouldShowAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    userId,
    undefined,
    undefined
  );
  const trackSupplementalPaymentTermsEntrypointClicked: React.MouseEventHandler<
    HTMLSpanElement
  > = () => {
    trackEvent({
      name: 'Supplemental Payment Terms Entrypoint Clicked',
      properties: {
        userId: userId,
        pageName: 'Footer',
      },
    });
  };
  return (
    <footer css={{ backgroundColor: theme.color.primaryBackground }}>
      <SectionContainer>
        <Section>
          <div css={{ width: '100%' }}>
            <nav
              aria-label="Footer Navigation"
              css={{
                display: 'grid',
                rowGap: 0,
                gridTemplateColumns: 'repeat(1,1fr)',
                [theme.media.small]: {
                  gridTemplateColumns: 'repeat(6,1fr)',
                },
              }}
            >
              <div
                css={{
                  marginBottom: theme.space.lg,
                  gridArea: 'auto/span 1',
                  [theme.media.small]: {
                    gridArea: 'auto/span 2',
                    marginBottom: 0,
                  },
                }}
              >
                <div className="mb-5 w-[160px]">
                  <HeadwayLogo color="green" text="Headway" />
                </div>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: theme.space.lg,
                    ' a': {
                      marginRight: theme.space.sm,
                    },
                    ' svg': {
                      width: 30,
                      height: 30,
                    },
                  }}
                >
                  <a
                    href="https://twitter.com/try_headway"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisuallyHidden>Twitter</VisuallyHidden>
                    <Twitter css={{ color: theme.color.primary }} />
                  </a>
                  <a
                    href="https://instagram.com/headway"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisuallyHidden>Instagram</VisuallyHidden>
                    <Instagram css={{ color: theme.color.primary }} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/find-headway-com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VisuallyHidden>LinkedIn</VisuallyHidden>
                    <LinkedIn css={{ color: theme.color.primary }} />
                  </a>
                </div>
              </div>
              <NavSection>
                <NavTitle>Get Care</NavTitle>
                <div className="mb-5">
                  <NavListLink
                    href={`${process.env.NEXT_PUBLIC_CARE_URL}/therapists`}
                  >
                    Find a therapist
                  </NavListLink>
                </div>
                <TruncatedList max={10}>
                  {liveMarkets?.length < 2 ? (
                    <NavListLink href={getProvidersSearchPath()}>
                      Find a provider
                    </NavListLink>
                  ) : (
                    <React.Fragment>
                      {sortBy(liveMarkets, 'displayName').map(
                        (market: MarketRead) => (
                          <NavListLink
                            key={market.id}
                            href={getProvidersSearchPath({
                              marketSlug: market.slug,
                            })}
                            onClick={() => {
                              trackProviderSearchStarted({
                                context: {
                                  interaction: LandingPageInteraction.Location,
                                  source: SearchEntry.GetCareFooter,
                                },
                                filters: {
                                  state: market.state,
                                },
                              });
                              trackProviderSearchCompleted({
                                context: {
                                  source: SearchEntry.GetCareFooter,
                                },
                                filters: {
                                  state: market.state,
                                },
                              });
                            }}
                          >
                            {market.displayName} providers
                          </NavListLink>
                        )
                      )}
                    </React.Fragment>
                  )}
                  {!shouldShowAnthemEAPExperience && (
                    <NavListLink href="/#how-it-works">
                      How it works
                    </NavListLink>
                  )}
                  {!shouldShowAnthemEAPExperience && (
                    <NavListLink href="/does-my-insurance-cover-therapy">
                      Does my insurance cover therapy?
                    </NavListLink>
                  )}
                </TruncatedList>
              </NavSection>
              <NavSection>
                <NavTitle>For Providers</NavTitle>
                <NavList>
                  <NavListLink
                    href="/for-providers"
                    aria-label="Learn more about providers"
                  >
                    Learn more
                  </NavListLink>
                  <NavListLink href="/for-providers#get-started">
                    Join Headway
                  </NavListLink>
                  <NavListLink href="/for-providers/refer">
                    Refer a provider
                  </NavListLink>
                  <NavListLink href={process.env.NEXT_PUBLIC_SIGMUND_URL || ''}>
                    Provider Portal
                  </NavListLink>
                  <NavListLink href="/resources/all">
                    Provider Resource Center
                  </NavListLink>
                </NavList>
              </NavSection>
              <NavSection>
                <NavTitle>For Health Plans</NavTitle>
                <NavList>
                  <NavListLink
                    href="/for-health-plans"
                    aria-label="Learn more about health plans"
                  >
                    Learn more
                  </NavListLink>
                </NavList>
              </NavSection>
              <NavSection>
                <NavTitle>Company</NavTitle>
                <NavList>
                  <NavListLink href="/about-us">About us</NavListLink>
                  <NavListLink href="/blog">Blog</NavListLink>
                  <NavListLink href="/press">Press</NavListLink>
                  <NavListLink href="/careers">
                    <span css={{ display: 'flex', alignItems: 'center' }}>
                      Careers
                      <Chip
                        label="We're hiring!"
                        size="small"
                        color="primary"
                        css={{
                          marginLeft: theme.space.xs,
                          color: theme.color.white,
                        }}
                      />
                    </span>
                  </NavListLink>
                  <NavListLink href="/legal/terms">
                    Terms of Service
                  </NavListLink>
                  <NavListLink
                    onClick={trackSupplementalPaymentTermsEntrypointClicked}
                    href="/legal/payment"
                  >
                    Supplemental Payment Terms
                    <Chip
                      label="Updated!"
                      size="small"
                      color="primary"
                      css={{
                        marginLeft: theme.space.xs,
                        color: theme.color.white,
                      }}
                    />
                  </NavListLink>
                  <NavListLink href="/legal/privacy">
                    <span css={{ display: 'flex', alignItems: 'center' }}>
                      Privacy Policy
                      <Chip
                        label="Updated!"
                        size="small"
                        color="primary"
                        css={{
                          marginLeft: theme.space.xs,
                          color: theme.color.white,
                        }}
                      />
                    </span>
                  </NavListLink>
                  <NavListLink href="/legal/ca-privacy">
                    CA Privacy Policy for Business Customers
                  </NavListLink>
                  <NavListLink href="/legal/wa-privacy">
                    Washington State Consumer Health Data Privacy Policy
                  </NavListLink>
                  <NavListLink href="/legal/privacy-practices">
                    Privacy Practice
                  </NavListLink>
                  <NavListLink href="/support">Help Center</NavListLink>
                  <NavListLink href="/contact?tags=agora_footer_entrypoint">
                    Contact Us
                  </NavListLink>
                </NavList>
              </NavSection>
              <div>
                <CaptionText>
                  © {new Date().getFullYear()} Therapymatch, Inc.
                </CaptionText>
              </div>
            </nav>
          </div>
        </Section>
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <Section>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <div className="hlx-typography-body">
              <div css={{ marginBottom: theme.space.base, maxWidth: '70ch' }}>
                If you're experiencing emotional distress, the resources below
                provide free and confidential support 24/7.{' '}
                <strong>If this is an emergency, call 911</strong>.
              </div>
              <div css={{ marginBottom: theme.space.base }}>
                <Link
                  href="https://suicidepreventionlifeline.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Suicide Prevention Lifeline
                </Link>
                <div>Call 1-800-273-8255</div>
              </div>
              <div>
                <Link
                  href="https://www.crisistextline.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Crisis Text Line
                </Link>
                <div>Text HOME to 741741</div>
              </div>
            </div>
          </div>
        </Section>
      </SectionContainer>
    </footer>
  );
};

interface TruncatedListProps {
  children: React.ReactNode;
  max: number;
}

function TruncatedList({ children, max }: TruncatedListProps) {
  let itemCount = 0;

  for (const child of React.Children.toArray(children)) {
    if (isFragment(child)) {
      itemCount += child.props.children.length;
    }

    itemCount++;
  }

  const [didUserExpand, setDidUserExpand] = useState(false);

  const truncated = !didUserExpand && itemCount > max;

  return (
    <>
      <ul
        css={[
          {
            listStyle: 'none',
            margin: 0,
            padding: 0,
          },
          truncated && {
            [`& > li:nth-of-type(n+${max + 1})`]: {
              display: 'none',
            },
          },
        ]}
      >
        {children}
      </ul>
      {truncated && (
        <Button
          variant="link"
          size="medium"
          onPress={() => {
            setDidUserExpand(true);
          }}
        >
          See more
        </Button>
      )}
    </>
  );
}
